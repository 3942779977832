import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { BaseOptionChartStyle } from "./components/chart/BaseOptionChart";
import ThemeProvider from "./theme";
import { store } from "./app/store";
import { Provider } from "react-redux";
import "./config/i18n";
import Routers from "./components/router/Routers";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <Provider store={store}>
          <BaseOptionChartStyle />
          <Routers />
        </Provider>
      </ThemeProvider>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
