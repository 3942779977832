import { getDocs, doc, collection, query, setDoc } from "firebase/firestore";
import { db } from "config/firebase";

const getDocument = (id) => {
  return doc(db, "main", id);
};

export const getMainContent = async () => {
  const q = query(collection(db, "main"));
  const querySnapshot = await getDocs(q);
  const mainContent = querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
  return mainContent;
};

export const updateMainContent = async (id, data) => {
  try {
    await setDoc(getDocument(id), data, { marge: true });
  } catch (e) {
    console.error(`Error while updating main content ${e}`);
    return false;
  }
};
