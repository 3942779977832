import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InfiniteScrollTrigger from "components/infinite-scroll/InfiniteScrollTrigger";
import Card from "components/card/Card";
import { Button } from "@mui/material";
import { skeletonGenerator } from "components/infinite-scroll/helpers";
import ProgramSkeleton from "components/infinite-scroll/skeletons/ArticleSkeleton";

const PredictionsList = ({
  predictions,
  currentUserId,
  currentUserDisplayName,
  currentUserType,
  isLoadingNextPage,
  hasMoreItems,
  getNextPage,
  page,
  setPage,
  createPrediction,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className="w-full h-full overflow-y-auto p-16"
      style={{
        background: "#e2e2e2",
      }}
    >
      <div className="m-auto">
        {(currentUserType === "admin" || currentUserType === "editor") && (
          <div className="w-full grid justify-items-end">
            <Button
              variant="outlined"
              onClick={async () => {
                const prediction = await createPrediction({
                  createdAt: Date.now(),
                  author: {
                    id: currentUserId,
                    displayName: currentUserDisplayName,
                  },
                });
                navigate(`/predictions/manage/${prediction.id}`);
              }}
              className="justify-items-end"
            >
              {t("predictions.predictionList.createNewPrediction")}
            </Button>
          </div>
        )}
        <div className="p-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
          {" "}
          {predictions.map(
            ({ title, poster, description, id, author, category }) => {
              return (
                <Card
                  key={id}
                  title={title}
                  image={poster}
                  category={category}
                  path={`/predictions/${id}`}
                  description={description}
                  primaryButtonText={t("card.view")}
                  secondaryButtonText={t("card.edit")}
                  onSecondaryButtonClick={() =>
                    navigate(`/predictions/manage/${id}`)
                  }
                  onPrimaryButtonClick={() => navigate(`/predictions/${id}`)}
                  showSecondaryButton={
                    author?.id === currentUserId ||
                    currentUserType === "admin" ||
                    currentUserType === "editor"
                  }
                  author={author}
                />
              );
            }
          )}
          {isLoadingNextPage && skeletonGenerator(<ProgramSkeleton />)}
          <InfiniteScrollTrigger
            isLoading={isLoadingNextPage}
            hasMoreItems={hasMoreItems}
            callback={getNextPage}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>
    </div>
  );
};

export default PredictionsList;
