import React from "react";
import useDashboard from "../../hooks/dashboard/useDashboard";
import Loading from "../../components/common/Loading";
import DashboardItems from "components/dashboard/DashboardItems";

const Dashboard = () => {
  const { isLoading, articlesLength, programsLength } = useDashboard();

  return isLoading ? (
    <Loading />
  ) : (
    <DashboardItems
      lengths={{
        articles: articlesLength,
        programs: programsLength,
      }}
    />
  );
};

export default Dashboard;
