import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  getUserById,
} from "api/users.api";

export const getUsersAsync = createAsyncThunk("users/get-all", async () => {
  const users = await getUsers();
  return users;
});

export const getUserByIdAsync = createAsyncThunk(
  "users/get-by-id",
  async (id) => {
    const user = await getUserById(id);
    return user;
  },
);

export const getCurrentUserByIdAsync = createAsyncThunk(
  "users/get-current-by-id",
  async (id) => {
    const user = await getUserById(id);
    return user;
  },
);

export const createUserAsync = createAsyncThunk(
  "users/create",
  async (user) => {
    const newUser = await createUser(user);
    return newUser;
  },
);

export const updateUserAsync = createAsyncThunk(
  "users/update",
  async ({ id, data }) => {
    await updateUser(id, data);
  },
);

export const deleteUserAsync = createAsyncThunk("users/delete", async (id) => {
  await deleteUser(id);
});
