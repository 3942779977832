import React from "react";
import { useTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { Button } from "@mui/material";
import useImageCropper from "hooks/image-cropper/useImageCropper";

const ImageCropperModal = ({ srcImg, aspect, onSave, onCancel }) => {
  const { t } = useTranslation();
  const { crop, setCrop, onCropComplete, setImage, imageUrl, handleCropClick } =
    useImageCropper({ aspect, onSave });

  return (
    <>
      <div className="flex flex-col items-center justify-between my-auto">
        <div className="px-3 pt-2">
          <ReactCrop
            crop={crop}
            onChange={setCrop}
            aspect={aspect}
            onComplete={onCropComplete}
          >
            <img
              src={srcImg}
              onLoad={setImage}
              style={{ maxHeight: "calc(100vh - 300px)" }}
              className="w-[500px] h-auto"
              alt="organization"
            />
          </ReactCrop>
        </div>
      </div>
      <div className="flex gap-2">
        <Button onClick={onCancel} disabled={!imageUrl} className="w-full mt-4">
          {t("cropper.cancel")}
        </Button>
        <Button
          onClick={handleCropClick}
          disabled={!imageUrl}
          className="w-full mt-4"
        >
          {t("cropper.crop")}
        </Button>
      </div>
    </>
  );
};

export default ImageCropperModal;
