import { createSlice } from "@reduxjs/toolkit";
import {
  getArticlesLengthAsync,
  getProgramsLengthAsync,
} from "./actions";

const initialState = {
  isLoading: true,
  articlesLength: 0,
  programsLength: 0,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getArticlesLengthAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getArticlesLengthAsync.fulfilled, (state, action) => {
        state.articlesLength = action.payload;
        state.isLoading = false;
      })
      .addCase(getArticlesLengthAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.articlesLength = 0;
      })
      .addCase(getProgramsLengthAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProgramsLengthAsync.fulfilled, (state, action) => {
        state.programsLength = action.payload;
        state.isLoading = false;
      })
      .addCase(getProgramsLengthAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.programsLength = 0;
      })
  },
});

export default dashboardSlice.reducer;
