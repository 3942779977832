import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InfiniteScrollTrigger from "components/infinite-scroll/InfiniteScrollTrigger";
import PredictorCard from "components/card/PredictorCard";
import { Button } from "@mui/material";
import { skeletonGenerator } from "components/infinite-scroll/helpers";
import ProgramSkeleton from "components/infinite-scroll/skeletons/ArticleSkeleton";

const PredictorsList = ({
  predictors,
  currentUserId,
  currentUserDisplayName,
  currentUserType,
  isLoadingNextPage,
  hasMoreItems,
  getNextPage,
  page,
  setPage,
  createPredictor,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className="w-full h-full overflow-y-auto p-16"
      style={{
        background: "#e2e2e2",
      }}
    >
      <div className="m-auto">
        {(currentUserType === "admin" || currentUserType === "editor") && (
          <div className="w-full grid justify-items-end">
            <Button
              variant="outlined"
              onClick={async () => {
                const predictor = await createPredictor({
                  createdAt: Date.now(),
                  author: {
                    id: currentUserId,
                    displayName: currentUserDisplayName,
                  },
                });
                navigate(`/predictors/manage/${predictor.id}`);
              }}
              className="justify-items-end"
            >
              {t("predictors.predictorsList.createNewPredictor")}
            </Button>
          </div>
        )}
        <div className="p-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
          {predictors.map(
            ({ firstName, lastName, description, id, image, degree }) => {
              return (
                <PredictorCard
                  key={id}
                  firstName={firstName}
                  lastName={lastName}
                  image={image}
                  degree={degree}
                  path={`/predictors/${id}`}
                  description={description}
                  primaryButtonText={t("card.view")}
                  secondaryButtonText={t("card.edit")}
                  onSecondaryButtonClick={() =>
                    navigate(`/predictors/manage/${id}`)
                  }
                  onPrimaryButtonClick={() => navigate(`/predictors/${id}`)}
                  showSecondaryButton={
                    currentUserType === "admin" || currentUserType === "editor"
                  }
                />
              );
            }
          )}
          {isLoadingNextPage && skeletonGenerator(<ProgramSkeleton />)}
          <InfiniteScrollTrigger
            isLoading={isLoadingNextPage}
            hasMoreItems={hasMoreItems}
            callback={getNextPage}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>
    </div>
  );
};

export default PredictorsList;
