import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const InfiniteScrollTrigger = ({
  isLoading,
  hasMoreItems,
  setPage,
  page,
  callback,
}) => {
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView && !isLoading && hasMoreItems) {
      callback();
      page && setPage(page + 1);
    }
  }, [inView, isLoading, hasMoreItems, callback]);

  return <>{!isLoading && <div ref={ref}>{inView && !hasMoreItems}</div>}</>;
};

export default InfiniteScrollTrigger;
