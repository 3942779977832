import { useState, useRef } from "react";

const useImageAttachment = ({ removeImage, onSave, crop }) => {
  const [fetch, setFetch] = useState(false);
  const [error, setError] = useState(null);
  const [srcImg, setSrcImg] = useState(null);
  const inputRef = useRef(null);

  const onImageUploadClick = async () => {
    setError(null);
    inputRef.current.click();
  };

  const onRemoveImageClick = async () => {
    setError(null);
    removeImage();
  };

  const onClick = (event) => {
    event.target.value = null;
  };

  const onFileInputChange = async (e) => {
    try {
      if (e.target.files.length > 0) {
        setFetch(true);
        const file = e.target.files[0];
        if (!crop) {
          onSave(file);
        } else {
          setSrcImg(URL.createObjectURL(file));
        }
      }
    } catch (err) {
      if (err.errors) {
        return setError(err.errors?.join(","));
      }
    } finally {
      setFetch(false);
    }
  };
  return {
    onRemoveImageClick,
    onImageUploadClick,
    inputRef,
    onFileInputChange,
    onClick,
    error,
    srcImg,
    setSrcImg,
    fetch,
  };
};

export default useImageAttachment;
