import React from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import Swiper from "../../components/swiper/Swiper";
import logo from "../../logo12.svg";
import { SlideContainer, Image, SwipeRight, SpanStyled } from "./styles";
import LoginForm from "../../components/auth/Login";

const LoginPage = () => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery("(min-width:600px)");
  const slide1 = (
    <SlideContainer>
      <Image src={logo} alt="logo" />
      <SpanStyled>{t("swipeForLogin")}</SpanStyled>
      <SwipeRight />
    </SlideContainer>
  );
  const slide2 = (
    <SlideContainer>
      <LoginForm />
    </SlideContainer>
  );
  return (
    <div>
      {!isDesktop && <Swiper slides={[slide1, slide2]} />}
      {isDesktop && <LoginForm />}
    </div>
  );
};

export default LoginPage;
