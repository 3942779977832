import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMainContent, updateMainContent } from "api/main.api";

export const getMainContentAsync = createAsyncThunk(
  "main/get-all",
  async () => {
    const mainContent = await getMainContent();
    return mainContent;
  }
);

export const updateMainContentAsync = createAsyncThunk(
  "main/update",
  async ({ id, data }) => {
    await updateMainContent(id, data);
  }
);
