import React from "react";
import shortid from "shortid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const DraggableList = ({ list, handleStateChange, template, ...rest }) => {
  // Reorder list state on drag-end
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (
      destination &&
      (destination.droppableId !== source.droppableId ||
        destination.index !== source.index)
    ) {
      const newItems = [...list];
      const draggableContent = list.find((item) => item.id === draggableId);

      newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, draggableContent);

      handleStateChange(newItems);
    }
  };
  // #endregion Event handlers

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`items-${shortid.generate()}`}>
        {(dropProvided) => (
          <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
            {list &&
              list.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => {
                    return React.cloneElement(template, {
                      item,
                      currentIndex: index,
                      provided,
                      handleStateChange,
                      ...rest,
                    });
                  }}
                </Draggable>
              ))}
            {dropProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
