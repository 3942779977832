import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import TextEditor from "components/textEditor/TextEditor";
import ImageUploader from "components/image-upload/ImageUploader";

const ArticleView = ({ article, isEditable }) => {
  const { t } = useTranslation();
  const coverUrl =
    article?.images?.cover &&
    (typeof article?.images?.cover === "string"
      ? article?.images?.cover
      : URL.createObjectURL(article?.images?.cover));
  const thumbnailUrl =
    article?.images?.thumbnail &&
    (typeof article?.images?.thumbnail === "string"
      ? article?.images?.thumbnail
      : URL.createObjectURL(article?.images?.thumbnail));
  return (
    <div className="h-full bg-cover bg-center mb-40 group">
      {thumbnailUrl && (
        <div className="relative">
          <ImageUploader
            isDisabled={!isEditable}
            imageComponent={
              <div
                className="relative w-full h-64 bg-black bg-opacity-50 hover:bg-gray-800"
                style={{ backgroundImage: `url(${thumbnailUrl})` }}
              />
            }
            text={t("articles.cover")}
            image={article?.images?.thumbnail || ""}
            setImage={(file, url) => {}}
          />
          {coverUrl && (
            <div className="absolute right-20 -bottom-20">
              <ImageUploader
                isDisabled={!isEditable}
                imageComponent={
                  <img
                    alt="group cover"
                    src={coverUrl}
                    className="rounded-md h-40 w-40"
                  />
                }
                text={t("articles.thumbnail")}
                image={coverUrl}
                setImage={(file, url) => {}}
              />
            </div>
          )}
        </div>
      )}
      <div className="px-10">
        <div className="w-full bg-white flex flex-row">
          <div className="w-3/4 my-auto">
            <Typography gutterBottom variant="h3" component="div">
              {article?.title}
            </Typography>
            <Typography gutterBottom variant="body1" component="span">
              {article?.description}
            </Typography>
          </div>
        </div>
        <div className="overflow-y-visible mb-24 mt-8">
          <Typography
            className="text-center w-3/4 mx-auto"
            gutterBottom
            variant="h4"
            component="div"
          >
            {article?.goal}
          </Typography>
          <div className="w-full">
            {article?.content && (
              <TextEditor
                isEditable={isEditable}
                value={article?.content}
                height="100%"
                width="100%"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleView;
