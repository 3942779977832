import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import TextEditor from "components/textEditor/TextEditor";
import ImageUploader from "components/image-upload/ImageUploader";

const PredictorView = ({ predictor, isEditable }) => {
  const { t } = useTranslation();
  return (
    <div className="h-full bg-cover bg-center mb-40 group">
      {predictor?.image && (
        <div className="relative">
          <ImageUploader
            isDisabled={!isEditable}
            imageComponent={
              <div
                className="relative w-full h-64 bg-black bg-opacity-50 hover:bg-gray-800"
                style={{ backgroundImage: `url(${predictor?.image})` }}
              />
            }
            text={t("programs.cover")}
            image={predictor?.image || ""}
            setImage={(file, url) => {}}
          />
        </div>
      )}
      <div className="px-10">
        <div className="w-full bg-white flex flex-row">
          <div className="w-3/4 my-auto">
            <Typography gutterBottom variant="h3" component="div">
              {predictor?.title}
            </Typography>
            <Typography gutterBottom variant="body1" component="span">
              {predictor?.description}
            </Typography>
          </div>
        </div>
        <div className="overflow-y-visible mb-24 mt-8">
          <Typography
            className="text-center w-3/4 mx-auto"
            gutterBottom
            variant="h4"
            component="div"
          >
            {predictor?.goal}
          </Typography>
          <div className="w-full">
            {predictor?.content && (
              <TextEditor
                isEditable={isEditable}
                value={predictor?.content}
                height="100%"
                width="100%"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PredictorView;
