import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Loading = () => {
  return (
    <Box
      sx={{
        display: "flex",
        left: "50%",
        top: "50%",
        marginLeft: "-50px",
        position: "absolute",
        marginTop: "-50px",
      }}
    >
      <CircularProgress size={100} />
    </Box>
  );
};

export default Loading;
