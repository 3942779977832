import React from "react";
import { useParams } from "react-router-dom";
import usePredictor from "hooks/predictors/usePredictor";
import PredictorView from "components/predictors/PredictorView";
import Loading from "components/common/Loading";

const Predictor = ({ isEditable }) => {
  const { id } = useParams();
  const { current, isLoading } = usePredictor({ id });
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <PredictorView predictor={current} isEditable={isEditable} />
      )}
    </div>
  );
};

export default Predictor;
