import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentArticle,
  selectIsLoading,
} from "redux-toolkit/articles/selectors";
import {
  getArticleByIdAsync,
  createArticleAsync,
  updateArticleAsync,
  deleteArticleAsync,
} from "redux-toolkit/articles/actions";

const useArticle = ({ id }) => {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentArticle);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    id && dispatch(getArticleByIdAsync(id));
  }, [dispatch, id]);

  const createArticle = useCallback(
    (newArticle) => {
      dispatch(createArticleAsync(newArticle));
    },
    [dispatch],
  );

  const updateArticle = useCallback(
    (id, data) => {
      dispatch(updateArticleAsync({ id, data }));
    },
    [dispatch],
  );

  const deleteArticle = useCallback(
    () => {
      dispatch(deleteArticleAsync(id));
    },
    [dispatch],
  );

  return {
    current,
    isLoading,
    createArticle,
    updateArticle,
    deleteArticle,
  };
};

export default useArticle;
