import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentProgram,
  selectIsLoading,
} from "redux-toolkit/programs/selectors";
import {
  getProgramByIdAsync,
  createProgramAsync,
  updateProgramAsync,
  deleteProgramAsync,
} from "redux-toolkit/programs/actions";

const useProgram = ({ id }) => {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentProgram);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    id && dispatch(getProgramByIdAsync(id));
  }, [dispatch, id]);

  const createProgram = useCallback(
    (newProgram) => {
      dispatch(createProgramAsync(newProgram));
    },
    [dispatch],
  );

  const updateProgram = useCallback(
    (id, data) => {
      dispatch(updateProgramAsync({ id, data }));
    },
    [dispatch],
  );

  const deleteProgram = useCallback(
    () => {
      dispatch(deleteProgramAsync(id));
    },
    [dispatch],
  );

  return {
    current,
    isLoading,
    createProgram,
    updateProgram,
    deleteProgram,
  };
};

export default useProgram;
