import React from "react";
import PredictorsList from "components/predictors/PredictorsList";
import Loading from "components/common/Loading";
import usePredictors from "hooks/predictors/usePredictors";
import useAuth from "hooks/useAuth";
import { createPredictor } from "api/predictors.api";

const Predictor = () => {
  const { predictors, isLoading, getNextPage, page, setPage, hasMoreItems } =
    usePredictors();
  const { currentUser } = useAuth();
  return isLoading && predictors.length === 0 ? (
    <Loading />
  ) : (
    <PredictorsList
      predictors={predictors}
      currentUserId={currentUser?.id}
      currentUserType={currentUser?.type}
      currentUserDisplayName={currentUser?.displayName}
      isLoadingNextPage={isLoading}
      hasMoreItems={hasMoreItems}
      getNextPage={getNextPage}
      page={page}
      setPage={setPage}
      createPredictor={createPredictor}
    />
  );
};

export default Predictor;
