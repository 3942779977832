import styled from "styled-components";
import SwipeRightAltIcon from "@mui/icons-material/SwipeRightAlt";

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SpanStyled = styled.span`
  color: black;
  font-size: 12px;
`;

const Image = styled.img`
  width: 300px;
  height: auto;
`;

const SwipeRight = styled(SwipeRightAltIcon)`
  color: black;
`;

export { SlideContainer, Image, SwipeRight, SpanStyled };
