import { useFormik } from "formik";
import { userFormSchema } from "libs/validation/schemas";
import mediaUploader from "api/mediaUploader.api";

const FIELDS = {
  DISPLAY_NAME: "displayName",
  ABOUT: "about",
  PHOTO_URL: "photoURL",
  POSTER_URL: "posterURL",
  DEGREE: "degree",
};

const useGroupForm = (props) => {
  const { user, update, id } = props;
  const userForm = useFormik({
    initialValues: {
      [FIELDS.DISPLAY_NAME]: user?.displayName || "",
      [FIELDS.PHOTO_URL]: user?.photoURL || "",
      [FIELDS.POSTER_URL]: user?.posterURL || "",
      [FIELDS.ABOUT]: user?.about || "",
      [FIELDS.DEGREE]: user?.degree || "",
    },
    enableReinitialize: true,
    validationSchema: userFormSchema,
    onSubmit: async (values) => {
      let posterURL = "";
      let photoURL = "";
      // const slug = `${values[FIELDS.TITLE].toLowerCase().replaceAll(" ", "-")}`;
      if (typeof values[FIELDS.PHOTO_URL] !== "string") {
        photoURL = await mediaUploader(
          values[FIELDS.PHOTO_URL],
          `users/photos/user-${id}`,
        );
      }
      if (typeof values[FIELDS.POSTER_URL] !== "string") {
        posterURL = await mediaUploader(
          values[FIELDS.POSTER_URL],
          `users/poster/user-${id}`,
        );
      }
      // create({
      //   ...values,
      //   photoURL,
      //   posterURL,
      //   createdAt: Date.now(),
      // });
      update(id, {
        ...values,
        photoURL: photoURL || user.photoURL,
        posterURL: posterURL || user.posterURL,
        updatedAt: Date.now(),
      });
    },
  });

  const { values, setFieldValue, handleSubmit, resetForm } = userForm;

  return {
    userForm,
    FIELDS,
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
  };
};

export default useGroupForm;
