import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPredictors,
  createPredictor,
  updatePredictors,
  deletePredictor,
  getPredictorById,
} from "api/predictors.api";

export const getPredictorsAsync = createAsyncThunk(
  "predictors/get-all",
  async () => {
    const data = await getPredictors();
    return data;
  },
);

export const getNextPredictorsAsync = createAsyncThunk(
  "predictors/get-all-next",
  async (last) => {
    const data = await getPredictors(last);
    return data;
  },
);

export const getPredictorByIdAsync = createAsyncThunk(
  "predictor/get-by-id",
  async (id) => {
    const predictor = await getPredictorById(id);
    const response = await fetch(predictor.content);
    const text = await response.text();
    return { ...predictor, content: text };
  },
);

export const createPredictorAsync = createAsyncThunk(
  "predictors/create",
  async (predictor) => {
    const predictors = await createPredictor(predictor);
    return predictors;
  },
);

export const updatePredictorAsync = createAsyncThunk(
  "predictor/update",
  async ({ id, data }) => {
    await updatePredictors(id, data);
  },
);

export const deletePredictorAsync = createAsyncThunk(
  "predictor/delete",
  async (id) => {
    await deletePredictor(id);
  },
);
