import { createSlice } from "@reduxjs/toolkit";
import { getMainContentAsync, updateMainContentAsync } from "./actions";

const initialState = {
  isLoading: true,
  content: [],
};

export const mainContentSlice = createSlice({
  name: "main",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMainContentAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getMainContentAsync.fulfilled, (state, action) => {
        state.content = action.payload;
        state.isLoading = false;
      })
      .addCase(getMainContentAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.content = [];
      })
      .addCase(updateMainContentAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateMainContentAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateMainContentAsync.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default mainContentSlice.reducer;
