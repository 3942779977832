import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import ImageUploader from "components/image-upload/ImageUploader";

const ProfileView = ({ profile, setFieldValue, onSave, isEditable }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();
  const posterURL =
    profile?.posterURL &&
    (typeof profile?.posterURL === "string"
      ? profile?.posterURL
      : URL.createObjectURL(profile?.posterURL));
  const photoURL =
    profile?.photoURL &&
    (typeof profile?.photoURL === "string"
      ? profile?.photoURL
      : URL.createObjectURL(profile?.photoURL));

  return (
    <div className="h-full bg-cover bg-center mb-40 group">
      {
        <div className="relative">
          <ImageUploader
            isDisabled={!isEdit}
            imageComponent={
              <div
                className="relative w-full h-80 bg-black bg-opacity-50 hover:bg-gray-800"
                style={{ backgroundImage: `url(${posterURL})` }}
              />
            }
            text={t("profile.poster")}
            image={profile?.posterURL || ""}
            setImage={(file, url) => setFieldValue("posterURL", file)}
          />
          <div className="absolute right-20 -bottom-20">
            <ImageUploader
              isDisabled={!isEdit}
              imageComponent={
                <img
                  alt="user photo"
                  src={photoURL}
                  className="rounded-md h-40 w-40"
                />
              }
              text={t("profile.photo")}
              image={profile?.photoURL}
              setImage={(file, url) => setFieldValue("photoURL", file)}
            />
          </div>
        </div>
      }
      <div className="px-10">
        <div className=" ml-24 mt-10 grid grid-cols-3 gap-4">
          <div className="col-span-2 w-full flex flex-col">
            <TextField
              className="mb-8"
              size="large"
              disabled={!isEdit}
              text={t("profile.displayName")}
              value={profile?.displayName}
              onChange={(e) => setFieldValue("displayName", e.target.value)}
            />
            <TextField
              className="mb-4"
              disabled={true}
              label={t("profile.email")}
              value={profile?.email}
            />
            <TextField
              className="mb-4"
              disabled={!isEdit}
              label={t("profile.degree")}
              value={profile?.degree}
              onChange={(e) => setFieldValue("degree", e.target.value)}
            />
          </div>
          <div>
            {isEditable && (
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  if (isEdit) {
                    onSave();
                  }
                  setIsEdit(!isEdit);
                }}
              >
                {isEdit ? t("profile.save") : t("profile.edit")}
              </Button>
            )}
          </div>
        </div>
        <div className="mt-16 w-2/3 ml-24 flex flex-col">
          <TextField
            disabled={!isEdit}
            multiline
            minRows={2}
            maxRows={15}
            label={t("profile.about")}
            value={profile?.about}
            onChange={(e) => setFieldValue("about", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileView;
