import React, { useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import AceEditor from "react-ace";
import SunEditor from "suneditor-react";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/webpack-resolver";
import "suneditor/dist/css/suneditor.min.css";

import options from "./settings";

const TextEditor = ({
  value = "<p></p>",
  setValue = () => {},
  disable = false,
  height,
  width,
  isEditable = false,
}) => {
  const [isCodeEditorOpen, setIsCodeEditorOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [showEditOptions, setShowEditOptions] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setIsClicked(false)}>
      <div
        onMouseEnter={() => {
          isEditable && setShowEditOptions(true);
        }}
        onMouseLeave={() => {
          isEditable && setShowEditOptions(false);
        }}
        className="w-full relative"
      >
        {isEditable && !isClicked && showEditOptions && (
          <div
            onClick={() => {
              setIsClicked(true);
            }}
            className="absolute flex items-center justify-center h-full w-full bg-black opacity-50 rounded-md z-20"
          >
            <span className="text-white">Խմբագրել բովանդակությունը</span>
          </div>
        )}
        {isEditable && isClicked && (
          <div>
            <button
              onClick={() => setIsCodeEditorOpen(false)}
              className={`${!isCodeEditorOpen && "bg-gray-200"} border p-2`}
            >
              <span className="text-xs text-gray-600">Visual</span>
            </button>
            <button
              onClick={() => setIsCodeEditorOpen(true)}
              className={`${isCodeEditorOpen && "bg-gray-200"} border p-2`}
            >
              <span className="text-xs text-gray-600">HTML</span>
            </button>
          </div>
        )}
        {!isCodeEditorOpen && (
          <SunEditor
            setContents={value}
            onChange={setValue}
            setOptions={options}
            hideToolbar={!isClicked}
            disable={isClicked ? false : true}
            height={isClicked ? "600px" : "hight"}
            width={width}
          />
        )}
        {isCodeEditorOpen && !disable && (
          <div className="w-full">
            <AceEditor
              mode="html"
              width="100%"
              theme="xcode"
              fontSize={16}
              height="460px"
              value={value}
              onChange={(value) => setValue(value)}
              editorProps={{ $blockScrolling: true }}
              wrapEnabled={true}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                useWorker: false,
              }}
            />
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default TextEditor;
