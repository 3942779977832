import { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";

const useMainPage = () => {
  const { currentUser, isLoading, signOut } = useAuth();
  const isOpenFromLocalStorage =
    localStorage.getItem("isOpenSidebar") === "true";
  const [isExpanded, setIsExpanded] = useState(isOpenFromLocalStorage);

  const [isOpenSidebarMobile, setIsOpenSidebarMobile] = useState(false);

  useEffect(() => {
    localStorage.setItem("isOpenSidebar", isExpanded);
  }, [isExpanded]);

  return {
    currentUser,
    isLoading,
    isExpanded,
    isOpenSidebarMobile,
    setIsExpanded,
    setIsOpenSidebarMobile,
    signOut,
  };
};

export default useMainPage;
