import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentPrediction,
  selectIsLoading,
} from "redux-toolkit/predictions/selectors";
import {
  getPredictionByIdAsync,
  createPredictionAsync,
  updatePredictionAsync,
  deletePredictionAsync,
} from "redux-toolkit/predictions/actions";

const usePrediction = ({ id }) => {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentPrediction);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    id && dispatch(getPredictionByIdAsync(id));
  }, [dispatch, id]);

  const createPrediction = useCallback(
    (newPrediction) => {
      dispatch(createPredictionAsync(newPrediction));
    },
    [dispatch],
  );

  const updatePrediction = useCallback(
    (id, data) => {
      dispatch(updatePredictionAsync({ id, data }));
    },
    [dispatch],
  );

  const deletePrediction = useCallback(
    () => {
      dispatch(deletePredictionAsync(id));
    },
    [id, dispatch],
  );

  return {
    current,
    isLoading,
    createPrediction,
    updatePrediction,
    deletePrediction,
  };
};

export default usePrediction;
