import React from "react";

const ArticleSkeleton = () => {
  return (
    <div className="animate-pulse h-56">
      <div className="bg-gray-600 rounded-md h-full">&ensp;</div>
    </div>
  );
};

export default ArticleSkeleton;
