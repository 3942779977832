import React from "react";
import { useParams } from "react-router-dom";
import ProfileView from "components/profile/ProfileView";
import useUser from "hooks/users/useUser";
import useAuth from "hooks/useAuth";
import Loading from "components/common/Loading";
import useUserForm from "hooks/users/useUserForm";

const Profile = () => {
  const { id } = useParams();
  const { currentUser, isLoading } = useAuth();
  const { selected, updateUser } = useUser(id || currentUser?.id);
  const { values, setFieldValue, handleSubmit } = useUserForm({
    user: selected,
    update: updateUser,
    id: currentUser?.id,
  });

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <ProfileView
          profile={{ ...values, email: selected?.email }}
          isEditable={currentUser?.id === selected.id}
          setFieldValue={setFieldValue}
          onSave={handleSubmit}
        />
      )}
    </div>
  );
};

export default Profile;
