import React from "react";
import { FaCheck } from "react-icons/fa";

const Stepper = ({ steps, activeStep, setActiveStep, isWithErrors }) => {
  return (
    <div className="px-10 flex justify-center">
      <div className="flex">
        {steps.map((item, index) => {
          const isActiveStep = activeStep === index;
          const styles =
            activeStep > index
              ? "border-green-600"
              : isActiveStep && "border-primary";
          return (
            <div key={index}>
              <div className="flex items-center">
                <div
                  className={`relative flex items-center justify-center w-10 h-10 transition-all duration-500`}
                >
                  <span
                    className={`ease absolute left-0 top-0 h-0 border-t-2 ${
                      isActiveStep && isWithErrors ? "border-red-600" : styles
                    } transition-all duration-500 ${
                      activeStep > index ? "w-full" : "w-0"
                    }`}
                  ></span>
                  <span
                    className={`ease absolute bottom-0 left-0 h-0 border-l-2 ${
                      isActiveStep && isWithErrors ? "border-red-600" : styles
                    } transition-all duration-500 ${
                      activeStep > index ? "h-full" : "h-0"
                    }`}
                  ></span>
                  <span
                    className={`ease absolute right-0 bottom-0 h-0 border-b-2 ${
                      isActiveStep && isWithErrors ? "border-red-600" : styles
                    } transition-all duration-500 ${
                      activeStep > index ? "w-full" : "w-0"
                    }`}
                  ></span>
                  <span
                    className={`ease absolute right-0 top-0 h-0 border-r-2 ${
                      isActiveStep && isWithErrors ? "border-red-600" : styles
                    } transition-all duration-500 ${
                      activeStep > index ? "h-full" : "h-0"
                    }`}
                  ></span>
                  <span
                    className={`text-sm text-gray-400 cursor-pointer ${
                      isActiveStep && "text-primary"
                    } ${isActiveStep && isWithErrors && "text-red-600"}`}
                    onClick={() => !isWithErrors && setActiveStep(index)}
                  >
                    {activeStep > index ? <FaCheck color="green" /> : index + 1}
                  </span>
                </div>
                {index !== steps.length - 1 && (
                  <div
                    className={`relative w-44 h-0 ${
                      activeStep <= index && "border-t-2"
                    }`}
                  >
                    <span
                      className={`ease absolute left-0 top-0 h-0 border-t-2 ${
                        isActiveStep && isWithErrors ? "border-red-600" : styles
                      } transition-all duration-500 ${
                        activeStep > index ? "w-full" : "w-0"
                      }`}
                    ></span>
                  </div>
                )}
              </div>
              <div className="mt-2 -ml-6">
                <span
                  className={`text-gray-400 text-sm font-semibold ${
                    isActiveStep && "text-gray-600"
                  } ${isActiveStep && isWithErrors && "text-red-600"}`}
                >
                  {item.desc}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Stepper;
