import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";
import { MdPhotoCamera } from "react-icons/md";

import ImageCropper from "./ImageCropper";
import useImageAttachment from "hooks/image-cropper/useImageAttachment";

const ImageAttachment = ({
  containerClassName,
  imageClassName,
  aspect,
  onSave,
  crop,
  image,
  removeImage,
  message,
  defaultUrl,
}) => {
  const { t } = useTranslation();
  const {
    onRemoveImageClick,
    onImageUploadClick,
    inputRef,
    onFileInputChange,
    onClick,
    error,
    srcImg,
    setSrcImg,
    fetch,
  } = useImageAttachment({ removeImage, onSave, crop });
  const [preView, setPreView] = useState(null);
  useEffect(() => {
    if (typeof image !== "string") {
      setPreView(URL.createObjectURL(image));
    } else {
      setPreView(image);
    }
  }, [image]);

  return (
    <div className="mb-4 justify-center items-center">
      <div className="w-full flex justify-center flex-col items-center gap-4 mb-4">
        <div className={containerClassName}>
          <img
            className={imageClassName}
            src={preView || defaultUrl}
            alt="cover"
          />
          {preView && (
            <div
              onClick={onRemoveImageClick}
              className={`absolute cursor-pointer w-fit h-fit top-1 right-1 ${
                fetch && "opacity-60"
              }`}
            >
              <IoClose color="red" size={30} />
            </div>
          )}
        </div>

        {message}
      </div>

      <div
        onClick={onImageUploadClick}
        className="cursor-pointer m-auto flex flex-col items-center w-full justify-center rounded-2xl"
      >
        <MdPhotoCamera color="blue" size={40} />
        <div className="font-bold text-sm leading-4.5 text-center tracking-wide">
          <p>{t("articles.cover")}</p>
        </div>
      </div>

      <input
        ref={inputRef}
        onChange={onFileInputChange}
        accept={"image/png, image/jpeg"}
        type="file"
        className="hidden absolute"
        disabled={fetch}
        onClick={onClick}
      />
      {crop && !error && srcImg && (
        <ImageCropper
          srcImg={srcImg}
          aspect={aspect}
          onSave={(blobUrl) => {
            setSrcImg(null);
            onSave(blobUrl);
          }}
          onCancel={() => {
            setSrcImg(null);
          }}
        />
      )}
    </div>
  );
};

export default ImageAttachment;
