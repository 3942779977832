import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  limit,
  addDoc,
  updateDoc,
  deleteDoc,
  startAfter,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "config/firebase";

const getDocument = (id) => {
  return doc(db, "predictors", id);
};

export const getData = async (reference) => {
  if (reference) {
    const docSnap = await getDoc(reference);
    return docSnap.exists ? { id: docSnap?.id, ...docSnap.data() } : null;
  }
  return null;
};

export const getPredictors = async (last) => {
  try {
    // Query to get the predictors
    const q = last
      ? query(
          collection(db, "predictors"),
          // orderBy("createdAt", "desc"),
          startAfter(last),
          limit(10),
        )
      : query(
          collection(db, "predictors"),
          // orderBy("createdAt", "desc"),
          limit(10),
        );

    const documentSnapshots = await getDocs(q);

    // Get the last visible document
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];

    const predictors = documentSnapshots.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });

    // Get the total count of predictors in the collection
    const countQuery = query(collection(db, "predictors"));
    const countSnapshot = await getCountFromServer(countQuery);
    const totalPredictorsCount = countSnapshot.data().count;
    console.log(totalPredictorsCount, "totalPredictorsCount");
    // Return predictors, last document, and total count of predictors
    return { predictors, last: lastVisible, totalPredictorsCount };
  } catch (error) {
    console.error(`Error while getting predictors: ${error}`);
    return error;
  }
};

export const getPredictorsLength = async () => {
  const collectionRef = collection(db, "predictors");
  const snapshot = await getDocs(collectionRef);
  return snapshot.size;
};

export const getPredictorById = async (id) => {
  const docRef = getDocument(id);
  const docSnap = await getDoc(docRef);
  const lesson = docSnap.data();
  return docSnap.exists ? { id: docSnap.id, ...lesson, reviewers: [] } : null;
};

export const createPredictor = async (predictor) => {
  try {
    const newPredictor = await addDoc(collection(db, "predictors"), predictor);
    return newPredictor;
  } catch (e) {
    console.error(`Error while creating predictor ${e}`);
    return false;
  }
};

export const updatePredictors = async (id, data) => {
  try {
    await updateDoc(getDocument(id), data);
  } catch (e) {
    console.error(`Error while updating predictor ${e}`);
    return false;
  }
};

export const deletePredictor = async (id) => {
  try {
    await deleteDoc(getDocument(id));
  } catch (e) {
    console.error(`Error while deleting predictor ${e}`);
    return false;
  }
};
