import { createAsyncThunk } from "@reduxjs/toolkit";
import { getArticlesLength } from "api/articles.api";
import { getProgramsLength } from "api/programs.api";

export const getArticlesLengthAsync = createAsyncThunk(
  "dashboard/get-article-length",
  async () => {
    const length = await getArticlesLength();
    return length;
  },
);

export const getProgramsLengthAsync = createAsyncThunk(
  "dashboard/get-programs-length",
  async () => {
    const length = await getProgramsLength();
    return length;
  },
);
