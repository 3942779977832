import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";

const CATEGORIES = {
  veritas: "Ճշմարտություն",
  whatToDo: "Ի՞նչ Անել",
  revelation: "Բացահայտված է",
  fair: "Արդարը",
  proverbial: "Առակն է խոսում",
  analysis: "Վերլուծություն",
  event: "Իրադարձություն",
  special: "Հատուկ",
};

export default function MediaCard({
  image,
  title,
  subject,
  lessonClass,
  author = {},
  primaryButtonText = "PBText",
  secondaryButtonText = "SBText",
  onPrimaryButtonClick = () => {},
  onSecondaryButtonClick = () => {},
  category = null,
  showPrimaryButton = true,
  showSecondaryButton = true,
  primaryButtonColor = "primary",
  categories = [],
}) {
  const profileLink = `/profile/${author?.id}`;
  return (
    <Card
      className="relative flex flex-col"
      sx={{ maxWidth: 345, height: "100%" }}
    >
      <CardMedia sx={{ height: 180 }} image={image} title={title} />
      <CardContent sx={{ flexGrow: 1 }}>
        <div className="absolute top-4 left-0 flex flex-col justify-center gap-1">
          {!!categories.length &&
            categories.map((key) => (
              <Typography
                className="bg-gray-800 bg-opacity-80 rounded-r-md p-2"
                variant="body2"
                color="white"
                key={key}
              >
                {CATEGORIES[key] || key}
              </Typography>
            ))}
        </div>
        {category && (
          <Typography className="text-wrap mb-2" variant="body2" color="purple">
            {category}
          </Typography>
        )}
        {subject && (
          <Typography className="text-wrap mb-2" variant="body2" color="purple">
            {subject}
          </Typography>
        )}
        {lessonClass && (
          <Typography className="text-wrap mb-2" variant="body2" color="green">
            {`Դասարան՝ ${lessonClass.join(", ")}`}
          </Typography>
        )}
        <Typography gutterBottom variant="subtitle2" component="div">
          {title}
        </Typography>
        {author && (
          <div className="mt-2 w-full flex flex-row">
            <Typography
              variant="body2"
              color="text.secondary"
              className="text-right"
            >
              {"Հեղինակ` "}
            </Typography>
            <Link
              className="ml-2"
              variant="subtitle2"
              component={RouterLink}
              to={profileLink}
            >
              {author?.displayName}
            </Link>
          </div>
        )}
      </CardContent>
      <CardActions className="b-0" sx={{ marginTop: "auto" }}>
        {showPrimaryButton && (
          <Button
            color={primaryButtonColor}
            size="small"
            onClick={onPrimaryButtonClick}
          >
            {primaryButtonText}
          </Button>
        )}
        {showSecondaryButton && (
          <Button size="small" onClick={onSecondaryButtonClick}>
            {secondaryButtonText}
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
