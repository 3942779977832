import Dashboard from "../../pages/dashboard/Dashboard";
import Login from "../../pages/login/LoginPage";
import Register from "components/auth/RegisterPage";
import NotFound from "../main/NotFound";
import Articles from "pages/articles/Articles";
import ManageArticle from "pages/articles/manage/ManageArticle";
import Article from "pages/articles/Article";
import Programs from "pages/programs/Programs";
import Predictions from "pages/predictions/Predictions";
import Prediction from "pages/predictions/Prediction";
import ManagePrediction from "pages/predictions/manage/ManagePrediction";
import Predictors from "pages/predictors/Predictors";
import Predictor from "pages/predictors/Predictor";
import ManagePredictor from "pages/predictors/manage/ManagePredictor";
import ManageProgram from "pages/programs/manage/ManageProgram";
import Program from "pages/programs/Program";
import Profile from "pages/profile/Profile";
import Main from "pages/main/Main";

export const protectedRoutes = [
  { path: "/", element: <Dashboard /> },
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/profile", element: <Profile /> },
  { path: "/profile/:id", element: <Profile /> },
  { path: "/articles", element: <Articles /> },
  { path: "/articles/:id", element: <Article isEditable={false} /> },
  { path: "/articles/manage", element: <ManageArticle isEditable={true} /> },
  {
    path: "/articles/manage/:id",
    element: <ManageArticle isEditable={true} />,
  },
  { path: "/programs", element: <Programs /> },
  { path: "/programs/:id", element: <Program isEditable={false} /> },
  { path: "/programs/manage", element: <ManageProgram isEditable={true} /> },
  {
    path: "/programs/manage/:id",
    element: <ManageProgram isEditable={true} />,
  },
  { path: "/predictions", element: <Predictions /> },
  { path: "/predictions/:id", element: <Prediction isEditable={false} /> },
  {
    path: "/predictions/manage",
    element: <ManagePrediction isEditable={true} />,
  },
  {
    path: "/predictions/manage/:id",
    element: <ManagePrediction isEditable={true} />,
  },

  { path: "/predictors", element: <Predictors /> },
  { path: "/predictors/:id", element: <Predictor isEditable={false} /> },
  {
    path: "/predictors/manage",
    element: <ManagePredictor isEditable={true} />,
  },
  {
    path: "/predictors/manage/:id",
    element: <ManagePredictor isEditable={true} />,
  },
  { path: "/main", element: <Main /> },
  { path: "*", element: <NotFound /> },
];

export const routes = [
  { path: "/login", element: <Login /> },
  { path: "/register", element: <Register /> },
  { path: "*", element: <NotFound /> },
];
