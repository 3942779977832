import React, { useState } from "react";
import usePrograms from "hooks/programs/usePrograms";
import useArticles from "hooks/articles/useArticles";
import MainContent from "components/main-content/MainContent";

const content = [
  {
    id: "analyses",
    label: "Վերլուծություններ",
  },
  {
    id: "specials",
    label: "Հատուկ Թողարկում",
  },
  {
    id: "events",
    label: "Իրադարձություններ",
  },
];

const Main = () => {
  const [programCategory, setProgramCategory] = useState("");
  const [category, setCategory] = useState("");
  const {
    programs,
    isLoading,
    getNextPage,
    page,
    setPage,
    hasMoreItems,
    programCategories,
  } = usePrograms(programCategory);
  const {
    articles,
    isLoading: isLoadingArticles,
    getNextPage: getNextPageArticles,
    page: articlesPage,
    setPage: setArticlesPage,
    hasMoreItems: hasMoreArticles,
  } = useArticles(category);
  return (
    <div className="mb-10">
      <div className="p-16">
        <MainContent
          items={programs}
          id="programs"
          label="Հաղորդումներ"
          isLoading={isLoading}
          hasMoreItems={hasMoreItems}
          getNextPage={getNextPage}
          page={page}
          setPage={setPage}
          category={programCategory}
          setCategory={setProgramCategory}
          categories={programCategories}
        />
        {content.map(({ id, label }) => (
          <MainContent
            key={id}
            items={articles}
            id={id}
            label={label}
            isLoading={isLoadingArticles}
            hasMoreItems={hasMoreArticles}
            getNextPage={getNextPageArticles}
            page={articlesPage}
            setPage={setArticlesPage}
            category={category}
            setCategory={setCategory}
          />
        ))}
      </div>
    </div>
  );
};

export default Main;
