import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedUser,
  selectIsLoading,
} from "redux-toolkit/users/selectors";
import {
  getUserByIdAsync,
  createUserAsync,
  updateUserAsync,
  deleteUserAsync,
} from "redux-toolkit/users/actions";

const useUser = (id) => {
  const dispatch = useDispatch();
  const selected = useSelector(selectSelectedUser);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    id && dispatch(getUserByIdAsync(id));
  }, [dispatch, id]);

  const createUser = useCallback(
    (newUser) => {
      dispatch(createUserAsync(newUser));
    },
    [dispatch],
  );

  const updateUser = useCallback(
    (id, data) => {
      dispatch(updateUserAsync({ id, data }));
    },
    [dispatch],
  );

  const deleteUser = useCallback(
    (id) => {
      dispatch(deleteUserAsync(id));
    },
    [dispatch],
  );

  return {
    selected,
    isLoading,
    createUser,
    updateUser,
    deleteUser,
  };
};

export default useUser;
