import { useState, useCallback, useEffect } from "react";

const useImageCropper = ({ aspect, onSave }) => {
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState();
  const [result, setResult] = useState(null);

  useEffect(() => {
    const width = image?.target?.width;
    const height = image?.target?.height;
    if (width < height * aspect) {
      const sideLength = width % 2 === 0 ? width : width - 1;
      setCrop({
        x: 0,
        y: 0,
        width: Math.ceil(sideLength),
        height: Math.ceil(sideLength / aspect),
        unit: "px",
      });
    } else {
      const sideHeight = height % 2 === 0 ? height : height - 1;
      setCrop({
        x: 0,
        y: 0,
        width: Math.ceil(sideHeight * aspect),
        height: sideHeight,
        unit: "px",
      });
    }
  }, [image, aspect]);

  const getCroppedImage = async (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          const croppedImageUrl = window.URL.createObjectURL(blob);
          resolve({ url: croppedImageUrl, file: blob });
        },
        "image/jpeg",
        1,
      );
    });
  };

  const makeClientCrop = useCallback(
    async (crop) => {
      if (image && crop.width && crop.height) {
        const croppedImage = await getCroppedImage(image.target, crop);
        setResult(croppedImage);
      }
    },
    [image],
  );

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const handleCropClick = useCallback(async () => {
    onSave(result.file);
  }, [result, onSave]);

  useEffect(() => {
    if (image && crop.width && crop.height && !result) {
      try {
        setTimeout(async () => {
          const croppedImage = await getCroppedImage(image.target, crop);
          setResult(croppedImage);
        }, 500);
      } catch (err) {
        // TODO: handle error
        // console.error("err: ", err.message);
      }
    }
  }, [image, crop, result]);

  return {
    crop,
    setCrop,
    onCropComplete,
    setImage,
    imageUrl: result?.url,
    handleCropClick,
  };
};

export default useImageCropper;
