import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "../auth/ProtectedRoute";
import MainWrapper from "../main/MainWrapper";
import { routes, protectedRoutes } from "./routes";

function Routers() {
  return (
    <Routes>
      {routes.map((route) => (
        <Route path={route.path} element={route.element} key={route.path} />
      ))}

      {protectedRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            route.hideWrapper ? (
              <ProtectedRoute>{route.element}</ProtectedRoute>
            ) : (
              <ProtectedRoute>
                <MainWrapper>{route.element}</MainWrapper>
              </ProtectedRoute>
            )
          }
        />
      ))}
    </Routes>
  );
}

export default Routers;
