import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CiImageOn } from "react-icons/ci";

const DEFAULT_IMAGE_URL =
  "https://assets.promptbase.com/DALLE_IMAGES%2F0wbTCfH5rym9qX6gukTX%2Fresized%2F1690684977463_800x800.webp?alt=media&token=c39b1396-3bb9-493f-8829-e963804d6b52";

const ImageUploader = ({
  text,
  setImage,
  errorMessage,
  image,
  isDisabled,
  imageComponent,
}) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [showEditOptions, setShowEditOptions] = useState(false);

  useEffect(() => {
    setImageSrc(typeof image === "string" ? image : URL.createObjectURL(image));
  }, [image]);

  const fileBrowser = React.useRef(null);
  return (
    <>
      <div
        onMouseEnter={() => {
          !isDisabled && setShowEditOptions(true);
        }}
        onMouseLeave={() => {
          !isDisabled && setShowEditOptions(false);
        }}
        className="relative flex justify-center h-full"
      >
        {imageComponent ? (
          imageComponent
        ) : (
          <img
            src={imageSrc || DEFAULT_IMAGE_URL}
            alt="logo"
            style={{ maxWidth: "300px", width: "100%", height: "auto" }}
          />
        )}
        {!isDisabled && showEditOptions && (
          <div className="absolute h-full w-full bg-black opacity-50 rounded-md" />
        )}
        {!isDisabled && showEditOptions && (
          <div
            onClick={() =>
              isDisabled ? () => {} : fileBrowser.current.click()
            }
            className="absolute flex justify-center gap-2 w-full items-center h-full opacity-100"
          >
            <CiImageOn color="white" size={40} />
            <span className="text-white cursor-pointer text-sm">
              {text || "Browse an image"}
            </span>

            <input
              onChange={(e) => {
                const file = e.target.files[0];

                if (file) {
                  setImage(file, URL.createObjectURL(file));
                }
              }}
              accept="image/*"
              type="file"
              style={{ display: "none" }}
              ref={fileBrowser}
            />
          </div>
        )}
      </div>
      {errorMessage && (
        <span className="text-red-600 text-sm">{errorMessage}</span>
      )}
    </>
  );
};

ImageUploader.propTypes = {
  className: PropTypes.string,
  setImage: PropTypes.func.isRequired,
  text: PropTypes.string,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
};

ImageUploader.defaultProps = {
  className: "",
  text: "",
  errorMessage: "",
  isDisabled: false,
};

export default ImageUploader;
