import { createSlice } from "@reduxjs/toolkit";
import {
  getPredictionsAsync,
  getPredictionByIdAsync,
  getNextPredictionsAsync,
} from "./actions";

const initialState = {
  isLoading: true,
  current: null,
  all: [],
  last: null,
  count: 0,
};

export const predictionsSlice = createSlice({
  name: "predictions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPredictionsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPredictionsAsync.fulfilled, (state, action) => {
        state.all = action.payload.predictions;
        state.last = action.payload.last;
        state.count = action.payload.totalPredictionsCount;
        state.isLoading = false;
      })
      .addCase(getPredictionsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getNextPredictionsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getNextPredictionsAsync.fulfilled, (state, action) => {
        state.all = [...state.all, ...action.payload.predictions];
        state.last = action.payload.last;
        state.isLoading = false;
      })
      .addCase(getNextPredictionsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getPredictionByIdAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPredictionByIdAsync.fulfilled, (state, action) => {
        state.current = action.payload;
        state.isLoading = false;
      })
      .addCase(getPredictionByIdAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.current = null;
      });
  },
});

export default predictionsSlice.reducer;
