import { createSlice } from "@reduxjs/toolkit";
import {
  getPredictorsAsync,
  getPredictorByIdAsync,
  getNextPredictorsAsync,
} from "./actions";

const initialState = {
  isLoading: true,
  current: null,
  all: [],
  last: null,
  count: 0,
};

export const predictorsSlice = createSlice({
  name: "predictors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPredictorsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPredictorsAsync.fulfilled, (state, action) => {
        state.all = action.payload.predictors;
        state.last = action.payload.last;
        state.count = action.payload.totalPredictorsCount;
        state.isLoading = false;
      })
      .addCase(getPredictorsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getNextPredictorsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getNextPredictorsAsync.fulfilled, (state, action) => {
        state.all = [...state.all, ...action.payload.predictors];
        state.last = action.payload.last;
        state.isLoading = false;
      })
      .addCase(getNextPredictorsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getPredictorByIdAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPredictorByIdAsync.fulfilled, (state, action) => {
        state.current = action.payload;
        state.isLoading = false;
      })
      .addCase(getPredictorByIdAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.current = null;
      });
  },
});

export default predictorsSlice.reducer;
