import * as React from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import { useTranslation } from "react-i18next";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function OutlinedCard({
  title,
  number,
  icon,
  path,
  color = "green",
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Card
      className="h-full border-4 group transition-all duration-500 shadow-2xl"
      elevation={24}
    >
      <CardContent>
        <Typography variant="h5"> {t(title)}</Typography>
        <div className="flex flex-row w-full justify-between items-center mt-4">
          <Typography color={color} variant="h3" className="px-4">
            {number}
          </Typography>
          {icon}
        </div>
      </CardContent>
      <CardActions className="opacity-0 group-hover:opacity-100 transition-all duration-700">
        <Button
          variant="contained"
          type="link"
          className="w-full bg-purple-900"
          onClick={() => {
            navigate(path);
          }}
        >
          <Typography className="px-8">Բոլորը ... </Typography>
        </Button>
      </CardActions>
    </Card>
  );
}
