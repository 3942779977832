import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  loginUserWithEmail,
  logOut,
  registerUser,
  registerUserWithGoogle,
} from "../api/auth.api";
import { createUserDocumentFromAuth } from "api/users.api";

const initialState = {
  currentUser: null,
  loading: false,
};

export const loginWithEmail = createAsyncThunk(
  "auth/loginWithEmail",
  async (payload) => {
    const { email, password } = payload;
    const result = await loginUserWithEmail(email, password);
    return result;
  },
);

export const signOutAsync = createAsyncThunk("auth/log-out", async () => {
  await logOut();
});

export const createUser = createAsyncThunk("auth/register", async (payload) => {
  const { email, password, additionalInfo } = payload;
  const result = await registerUser(email, password);
  await createUserDocumentFromAuth(result, additionalInfo);
  return result;
});

export const signInWithGoogleAsync = createAsyncThunk(
  "auth/signIn-with-google",
  async () => {
    const result = await registerUserWithGoogle();
    return result;
  },
);

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    loading: (state, action) => {
      state.loading = !!action.payload;
    },
    logout: (state) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginWithEmail.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loginWithEmail.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.loading = false;
      })
      .addCase(loginWithEmail.rejected, (state) => {
        state.currentUser = null;
        state.loading = false;
      })
      .addCase(createUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.loading = false;
      })
      .addCase(createUser.rejected, (state) => {
        state.currentUser = null;
        state.loading = false;
      })
      .addCase(signInWithGoogleAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(signInWithGoogleAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(signInWithGoogleAsync.rejected, (state) => {
        state.currentUser = null;
        state.loading = false;
      });
  },
});

export const { loading, logout } = appSlice.actions;

export const selectCurrentUser = (state) => state.app.loading;

export default appSlice.reducer;
