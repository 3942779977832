import { useTranslation } from "react-i18next";
// material
import { Stack, Button, Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
// component
import Iconify from "../Iconify";
import useAuth from "../../hooks/useAuth";

// ----------------------------------------------------------------------

export default function AuthSocial() {
  const { t } = useTranslation();
  const { signInWithGoogle } = useAuth();
  const navigate = useNavigate();
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button
          onClick={() =>
            signInWithGoogle().then(() => navigate("/", { replace: true }))
          }
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
        >
          <Iconify
            icon="eva:google-fill"
            color="#DF3E30"
            width={22}
            height={22}
          />
        </Button>

        {/* <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
        </Button>

        <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
        </Button> */}
      </Stack>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {t("login.or")}
        </Typography>
      </Divider>
    </>
  );
}
