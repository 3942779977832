import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  limit,
  addDoc,
  updateDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "config/firebase";

const getDocument = (id) => {
  return doc(db, "users", id);
};

export const getData = async (reference) => {
  if (reference) {
    const docSnap = await getDoc(reference);
    return docSnap.exists ? { id: docSnap?.id, ...docSnap.data() } : null;
  }
  return null;
};

export const getUsers = async () => {
  const q = query(collection(db, "users"), limit(12));
  const querySnapshot = await getDocs(q);
  const users = querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
  return users;
};

export const getUserById = async (id) => {
  const docRef = getDocument(id);
  const docSnap = await getDoc(docRef);
  const user = docSnap.data();
  return docSnap.exists ? { ...user, id: docSnap.id } : null;
};

export const createUser = async (user) => {
  try {
    await addDoc(collection(db, "users"), user);
    return user;
  } catch (e) {
    console.error(`Error while creating user ${e}`);
    return false;
  }
};

export const updateUser = async (id, data) => {
  try {
    await updateDoc(getDocument(id), data);
  } catch (e) {
    console.error(`Error while updating user ${e}`);
    return false;
  }
};

export const deleteUser = async (id) => {
  try {
    await deleteDoc(getDocument(id));
  } catch (e) {
    console.error(`Error while deleting user ${e}`);
    return false;
  }
};

export const createUserDocumentFromAuth = async (user, additionalInfo) => {
  if (!user) return;

  const userDocRef = doc(db, "users", user.uid);
  const userSnapshot = await getDoc(userDocRef);

  if (!userSnapshot.exists()) {
    const { displayName, email, photoURL } = user;
    const createdAt = new Date();

    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        photoURL,
        createdAt: createdAt.toISOString(),
        ...additionalInfo,
      });
    } catch (error) {
      // console.log("an error has occurred: ", error);
    }
  }
  return user;
};
