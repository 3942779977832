import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectArticlesLength,
  selectIsLoading,
  selectProgramsLength,
} from "redux-toolkit/dashboard/selectors";
import {
  getArticlesLengthAsync,
  getProgramsLengthAsync,
} from "redux-toolkit/dashboard/actions";

const useDashboard = () => {
  const dispatch = useDispatch();
  const articlesLength = useSelector(selectArticlesLength);
  const isLoading = useSelector(selectIsLoading);
  const programsLength = useSelector(selectProgramsLength);
;

  useEffect(() => {
    dispatch(getArticlesLengthAsync());
    dispatch(getProgramsLengthAsync());
  }, [dispatch]);

  return {
    articlesLength,
    programsLength,
    isLoading,
  };
};

export default useDashboard;
