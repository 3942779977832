import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPredictions,
  createPrediction,
  updatePredictions,
  deletePrediction,
  getPredictionById,
} from "api/predictions.api";

export const getPredictionsAsync = createAsyncThunk(
  "predictions/get-all",
  async () => {
    const data = await getPredictions();
    return data;
  },
);

export const getNextPredictionsAsync = createAsyncThunk(
  "predictions/get-all-next",
  async (last) => {
    const data = await getPredictions(last);
    return data;
  },
);

export const getPredictionByIdAsync = createAsyncThunk(
  "prediction/get-by-id",
  async (id) => {
    const prediction = await getPredictionById(id);
    const response = await fetch(prediction.content);
    const text = await response.text();
    return { ...prediction, content: text };
  },
);

export const createPredictionAsync = createAsyncThunk(
  "predictions/create",
  async (prediction) => {
    const predictions = await createPrediction(prediction);
    return predictions;
  },
);

export const updatePredictionAsync = createAsyncThunk(
  "prediction/update",
  async ({ id, data }) => {
    await updatePredictions(id, data);
  },
);

export const deletePredictionAsync = createAsyncThunk(
  "prediction/delete",
  async (id) => {
    await deletePrediction(id);
  },
);
