import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import defaultuserprofile from "../../assets/defaultuserprofile.webp";
import LanguageSwitcher from "../language/LanguageSwitcher";

const Navbar = ({ currentUser, mode }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isDark = mode === "dark";
  return (
    <div className="flex justify-between p-2 px-2 relative h-16 bg-[#dfe0f0]">
      <div>
        <Link to="/dashboard">
          <img
            src="../../logo.svg"
            alt="logo"
            width={180}
            height={180}
            className="-my-8"
          />
        </Link>
      </div>
      <div className="flex items-center ml-auto">
        {/* {searchComponent} */}
        <div
          className="flex items-center gap-2 cursor-pointer p-1 rounded-lg"
          onClick={() => setIsOpen(!isOpen)}
          id="profile"
        >
          <LanguageSwitcher />
          {currentUser?.photoURL ? (
            <img
              src={currentUser.photoURL}
              className="rounded-full w-9 h-9"
              height={20}
              width={20}
              alt="avatar"
            />
          ) : (
            <img
              src={defaultuserprofile}
              className="rounded-full w-9 h-9"
              height={20}
              width={20}
              alt="avatar"
            />
          )}

          <p className="hidden sm:inline-flex">
            <span
              className={`text-white text-16 ${
                !isDark && "text-gray-800 dark:text-white"
              }`}
            >
              {t("dashboard.userProfile.hi", " ")}
            </span>
            <span className="text-[#54094e] font-medium ml-1 text-16">
              {currentUser?.displayName ? currentUser?.displayName : "user"}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
