import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
// @mui
import { Link, Container, Typography } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Page from "../Page";
import LanguageSwitcher from "../language/LanguageSwitcher";
// sections
import LoginForm from "./LoginForm";
import AuthSocial from "./AuthSocial";
import logo from "../../logo12.svg";
// import image from "image.jpg"
import {
  Image,
  RootStyle,
  HeaderStyle,
  SectionStyle,
  ContentStyle,
} from "./styles";

export default function Login() {
  const { t } = useTranslation();
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          {/* <Image width="70px" src={logo} alt="logo" /> */}
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              <Link variant="subtitle2" component={RouterLink} to="/register">
                {t("login.registerLink")}
              </Link>
            </Typography>
          )}
          <LanguageSwitcher />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Image width="600px" src={logo} alt="logo" />
            <Typography variant="hւ" sx={{ px: 5, mt: 10, mb: 5 }}>
              {t("login.disclaimer")}
            </Typography>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: "text.primary" }}
            >
              {t("login.title")}
            </Typography>

            <Typography sx={{ color: "text.secondary", mb: 5 }}>
              {t("login.loginOptions")}
            </Typography>

            <AuthSocial />

            <LoginForm />

            {!smUp && (
              <Typography
                color="black"
                variant="body2"
                align="center"
                sx={{ color: "text.secondary", mt: 3 }}
              >
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  {t("login.registerLink")}
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
