import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function PredictorCard({
  image,
  firstName,
  lastName,
  description,
  degree,
  primaryButtonText = "PBText",
  secondaryButtonText = "SBText",
  onPrimaryButtonClick = () => {},
  onSecondaryButtonClick = () => {},
  showPrimaryButton = true,
  showSecondaryButton = true,
  primaryButtonColor = "primary",
}) {
  return (
    <Card
      className="relative flex flex-col"
      sx={{ maxWidth: 345, height: "100%" }}
    >
      <CardMedia sx={{ height: 180 }} image={image} title={firstName} />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2" component="div">
          {firstName} {lastName}
        </Typography>
        <Typography gutterBottom component="div">
          {degree}
        </Typography>
        <Typography component="div">
          {description}
        </Typography>
      </CardContent>
      <CardActions className="b-0" sx={{ marginTop: "auto" }}>
        {showPrimaryButton && (
          <Button
            color={primaryButtonColor}
            size="small"
            onClick={onPrimaryButtonClick}
          >
            {primaryButtonText}
          </Button>
        )}
        {showSecondaryButton && (
          <Button size="small" onClick={onSecondaryButtonClick}>
            {secondaryButtonText}
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
