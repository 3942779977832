const defaultFonts = [
  "Arial",
  "Comic Sans MS",
  "Courier New",
  "Impact",
  "Georgia",
  "Tahoma",
  "Trebuchet MS",
  "Verdana",
];

const sortedFontOptions = [
  "Logical",
  "Salesforce Sans",
  "Garamond",
  "Sans-Serif",
  "Serif",
  "Times New Roman",
  "Helvetica",
  ...defaultFonts,
].sort();

const options = {
  buttonList: [
    ["undo", "redo"],
    ["font", "fontSize"],
    // ['paragraphStyle', 'blockquote'],
    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
    ["fontColor", "hiliteColor"],
    ["align", "list", "lineHeight"],
    ["outdent", "indent"],

    ["table", "horizontalRule", "link", "image", "video"],
    // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
    // ['imageGallery'], // You must add the "imageGalleryUrl".
    ["fullScreen", "showBlocks"],
    ["preview", "print"],
    ["removeFormat"],

    // ['save', 'template'],
    // '/', Line break
  ], // Or Array of button list, eg. [['font', 'align'], ['image']]
  // defaultTag: "div",
  minHeight: "360px",
  showPathLabel: false,
  font: sortedFontOptions,
};

export default options;
