import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ImageAttachment from "components/image-cropper/ImageAttachment";
const DEFAULT_IMAGE_URL =
  "https://assets.promptbase.com/DALLE_IMAGES%2F0wbTCfH5rym9qX6gukTX%2Fresized%2F1690684977463_800x800.webp?alt=media&token=c39b1396-3bb9-493f-8829-e963804d6b52";

const PredictionOverview = ({
  overview,
  errors,
  setFieldValue,
  deletePrediction,
  id,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-8 items-center w-full px-20 overflow-auto max-h-screen">
      <div className="w-3/4 flex flex-col gap-2">
        <div>
          <Checkbox
            checked={overview?.published}
            color="primary"
            onChange={(e) => {
              setFieldValue("published", !overview?.published);
            }}
            value="Հրապարակել"
          />
          <span>Հրապարակված է</span>
        </div>
        <TextField
          size="small"
          value={overview?.title || ""}
          onChange={(e) => setFieldValue("title", e.target.value)}
          fullWidth
          label={t("predictions.title")}
          error={Boolean(errors?.title)}
          helperText={t(errors?.title)}
        />
        <TextField
          size="small"
          value={overview?.description || ""}
          onChange={(e) => setFieldValue("description", e.target.value)}
          fullWidth
          label={t("predictions.desc")}
          error={Boolean(errors?.description)}
          helperText={t(errors?.description)}
        />
        <div className="grid grid-cols-3">
          <FormControlLabel
            control={
              <Checkbox
                checked={overview?.categories?.includes("veritas")}
                color="primary"
                onChange={(e) =>
                  setFieldValue(
                    "categories",
                    overview?.categories?.includes(e.target.value)
                      ? overview?.categories?.filter(
                          (cat) => cat !== e.target.value
                        )
                      : [...(overview?.categories || []), e.target.value]
                  )
                }
                value="veritas"
              />
            }
            label={t("predictions.categories.veritas")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={overview?.categories?.includes("whatToDo")}
                color="primary"
                onChange={(e) =>
                  setFieldValue(
                    "categories",
                    overview?.categories?.includes(e.target.value)
                      ? overview?.categories?.filter(
                          (cat) => cat !== e.target.value
                        )
                      : [...(overview?.categories || []), e.target.value]
                  )
                }
                value="whatToDo"
              />
            }
            label={t("predictions.categories.whatToDo")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={overview?.categories?.includes("revelation")}
                color="primary"
                onChange={(e) =>
                  setFieldValue(
                    "categories",
                    overview?.categories?.includes(e.target.value)
                      ? overview?.categories?.filter(
                          (cat) => cat !== e.target.value
                        )
                      : [...(overview?.categories || []), e.target.value]
                  )
                }
                value="revelation"
              />
            }
            label={t("predictions.categories.revelation")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={overview?.categories?.includes("fair")}
                color="primary"
                onChange={(e) =>
                  setFieldValue(
                    "categories",
                    overview?.categories?.includes(e.target.value)
                      ? overview?.categories?.filter(
                          (cat) => cat !== e.target.value
                        )
                      : [...(overview?.categories || []), e.target.value]
                  )
                }
                value="fair"
              />
            }
            label={t("predictions.categories.fair")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={overview?.categories?.includes("proverbial")}
                color="primary"
                onChange={(e) =>
                  setFieldValue(
                    "categories",
                    overview?.categories?.includes(e.target.value)
                      ? overview?.categories?.filter(
                          (cat) => cat !== e.target.value
                        )
                      : [...(overview?.categories || []), e.target.value]
                  )
                }
                value="proverbial"
              />
            }
            label={t("predictions.categories.proverbial")}
          />
        </div>
      </div>
      <div className="flex gap-10 h-full">
        <ImageAttachment
          crop
          aspect={1.5}
          containerClassName="relative w-full"
          onSave={(file, url) => setFieldValue("poster", file)}
          imageClassName="object-cover w-[960px] h-full"
          image={overview?.poster}
          removeImage={() => setFieldValue("poster", "")}
          defaultUrl={DEFAULT_IMAGE_URL}
        />
      </div>
      {id && (
        <Button
          color="error"
          variant="contained"
          className="mb-24"
          onClick={() => {
            setOpen(true);
          }}
        >
          Ջնջել կանխատեսումը
        </Button>
      )}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle> Հաստատում </DialogTitle>
        <DialogContent dividers>
          Դուք համոզված ե՞ք, որ կանխատեսումը պետք է ջնջվի։
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Չեղարկել
          </Button>
          <Button
            onClick={() => {
              deletePrediction();
              setOpen(true);
              navigate("/predictions");
            }}
          >
            Ջնջել
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PredictionOverview;
