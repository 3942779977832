import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectArticles,
  selectIsLoading,
  selectLastArticle,
  selectArticleCount,
} from "redux-toolkit/articles/selectors";
import {
  getArticlesAsync,
  getNextArticlesAsync,
} from "redux-toolkit/articles/actions";

const useArticles = (category) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const articles = useSelector(selectArticles);
  const isLoading = useSelector(selectIsLoading);
  const last = useSelector(selectLastArticle);
  const count = useSelector(selectArticleCount);

  useEffect(() => {
    dispatch(getArticlesAsync(category));
  }, [dispatch, category]);

  const getNextPage = () => {
    if (last && count > articles.length) {
      dispatch(getNextArticlesAsync({last, category}));
      setPage(page + 1);
    }
  };

  return {
    articles,
    isLoading,
    hasMoreItems: count > articles.length,
    page,
    setPage,
    getNextPage,
  };
};

export default useArticles;
