import React from "react";
import Card from "./Card";
import bgImage from "assets/dashboardImage.jpg";
import { dashboardItems } from "constants/data";

const DashboardItems = ({ lengths }) => {
  return (
    <div
      className="h-full bg-cover bg-center"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="m-auto">
        <div className="p-16 grid grid-cols-4 gap-4 w-full">
          {dashboardItems.map(({ slug, name, color, icon, path }) => (
            <Card
              key={name}
              title={name}
              color={color}
              icon={icon}
              path={path}
              number={lengths[slug]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardItems;
