import { createSlice } from "@reduxjs/toolkit";
import {
  getUsersAsync,
  getUserByIdAsync,
  getCurrentUserByIdAsync,
} from "./actions";

const initialState = {
  isLoading: false,
  current: null,
  selectedUser: null,
  all: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsersAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUsersAsync.fulfilled, (state, action) => {
        state.all = action.payload;
        state.isLoading = false;
      })
      .addCase(getUsersAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getUserByIdAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserByIdAsync.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
        state.isLoading = false;
      })
      .addCase(getUserByIdAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.selectedUser = null;
      })
      .addCase(getCurrentUserByIdAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCurrentUserByIdAsync.fulfilled, (state, action) => {
        state.current = action.payload;
        state.isLoading = false;
      })
      .addCase(getCurrentUserByIdAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.current = null;
      });
  },
});

export default usersSlice.reducer;
