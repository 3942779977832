import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPredictors,
  selectIsLoading,
  selectLastPredictor,
  selectPredictorCount,
} from "redux-toolkit/predictors/selectors";
import {
  getPredictorsAsync,
  getNextPredictorsAsync,
} from "redux-toolkit/predictors/actions";

const usePredictors = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const predictors = useSelector(selectPredictors);
  const isLoading = useSelector(selectIsLoading);
  const last = useSelector(selectLastPredictor);
  const count = useSelector(selectPredictorCount);

  useEffect(() => {
    dispatch(getPredictorsAsync());
  }, [dispatch]);

  const getNextPage = () => {
    if (last && count > predictors.length) {
      dispatch(getNextPredictorsAsync(last));
      setPage(page + 1);
    }
  };

  return {
    predictors,
    isLoading,
    hasMoreItems: count > predictors.length,
    page,
    setPage,
    getNextPage,
  };
};

export default usePredictors;
