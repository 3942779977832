import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPredictions,
  selectIsLoading,
  selectLastPrediction,
  selectPredictionCount,
} from "redux-toolkit/predictions/selectors";
import {
  getPredictionsAsync,
  getNextPredictionsAsync,
} from "redux-toolkit/predictions/actions";

const usePredictions = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const predictions = useSelector(selectPredictions);
  const isLoading = useSelector(selectIsLoading);
  const last = useSelector(selectLastPrediction);
  const count = useSelector(selectPredictionCount);

  useEffect(() => {
    dispatch(getPredictionsAsync());
  }, [dispatch]);

  const getNextPage = () => {
    if (last && count > predictions.length) {
      dispatch(getNextPredictionsAsync(last));
      setPage(page + 1);
    }
  };

  return {
    predictions,
    isLoading,
    hasMoreItems: count > predictions.length,
    page,
    setPage,
    getNextPage,
  };
};

export default usePredictions;
