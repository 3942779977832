import React from "react";
import PredictionsList from "components/predictions/PredictionsList";
import Loading from "components/common/Loading";
import usePredictions from "hooks/predictions/usePredictions";
import useAuth from "hooks/useAuth";
import { createPrediction } from "api/predictions.api";

const Programs = () => {
  const { predictions, isLoading, getNextPage, page, setPage, hasMoreItems } =
    usePredictions();
  const { currentUser } = useAuth();
  return isLoading && predictions.length === 0 ? (
    <Loading />
  ) : (
    <PredictionsList
      predictions={predictions}
      currentUserId={currentUser?.id}
      currentUserType={currentUser?.type}
      currentUserDisplayName={currentUser?.displayName}
      isLoadingNextPage={isLoading}
      hasMoreItems={hasMoreItems}
      getNextPage={getNextPage}
      page={page}
      setPage={setPage}
      createPrediction={createPrediction}
    />
  );
};

export default Programs;
