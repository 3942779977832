import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const ProtectedRoute = ({ children }) => {
  const { currentUser, isLoading } = useAuth();
  if (!currentUser && !isLoading) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
