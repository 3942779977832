import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTranslation } from "react-i18next";
import { Link, Container, Typography } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Page from "../Page";
import LanguageSwitcher from "../language/LanguageSwitcher";
// sections
import RegisterForm from "pages/register/RegisterForm";
import AuthSocial from "./AuthSocial";
import logo from "../../logo12.svg";

import {
  Image,
  RootStyle,
  HeaderStyle,
  SectionStyle,
  ContentStyle,
} from "./styles";

export default function RegisterPage() {
  const { t } = useTranslation();
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");

  return (
    <Page title="Register">
      <RootStyle>
        <HeaderStyle>
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              {t("register.loginLink")} {""}
              <Link variant="subtitle2" component={RouterLink} to="/login">
                {t("register.signIn")}
              </Link>
            </Typography>
          )}
          <LanguageSwitcher />
        </HeaderStyle>
        {mdUp && (
          <SectionStyle>
            <Image width="600px" src={logo} alt="logo" />
            <Typography variant="hւ" sx={{ px: 5, mt: 10, mb: 5 }}>
              {t("login.disclaimer")}
            </Typography>
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: "text.primary" }}
            >
              {t("login.title")}
            </Typography>

            <Typography sx={{ color: "text.secondary", mb: 5 }}>
              {t("register.registerOptions")}
            </Typography>

            <AuthSocial />

            <RegisterForm />

            {!smUp && (
              <Typography variant="body2" sx={{ mt: 3, textAlign: "center" }}>
                Already have an account?{" "}
                <Link variant="subtitle2" to="/login" component={RouterLink}>
                  Login
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
