import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth } from "../config/firebase";

const loginUserWithEmail = async (email, password) => {
  try {
    const userCredentials = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );
    const user = userCredentials.user;
    return user;
  } catch {
    return false;
  }
};

const logOut = async () => {
  try {
    await signOut(auth);
  } catch {
    return false;
  }
};

const registerUser = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );
    const user = userCredential.user;
    return user;
  } catch {
    return false;
  }
};

const registerUserWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  await signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      GoogleAuthProvider.credentialFromResult(result);
    })
    .catch((error) => {
      // Handle Errors here.
      console.error(error);
    });
};

const observer = (callback) => onAuthStateChanged(auth, callback);

export {
  registerUser,
  loginUserWithEmail,
  logOut,
  observer,
  registerUserWithGoogle,
};
