import React from "react";
import { useParams } from "react-router-dom";
import useProgram from "hooks/programs/useProgram";
import ProgramView from "components/programs/ProgramView";
import Loading from "components/common/Loading";

const Program = ({ isEditable }) => {
  const { id } = useParams();
  const { current, isLoading } = useProgram({ id });
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <ProgramView program={current} isEditable={isEditable} />
      )}
    </div>
  );
};

export default Program;
