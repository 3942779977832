import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  loginWithEmail,
  signOutAsync,
  createUser,
  signInWithGoogleAsync,
} from "../app/app-slice";
import { observer } from "../api/auth.api";
import { createUserDocumentFromAuth } from "api/users.api";
import { getCurrentUserByIdAsync } from "redux-toolkit/users/actions";
import { selectCurrentUser } from "redux-toolkit/users/selectors";

const useAuth = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(
    () => {
      setIsLoading(true);
      const unsubscribe = observer((user) => {
        if (user) {
          createUserDocumentFromAuth(user);
          dispatch(getCurrentUserByIdAsync(user.uid))
            .unwrap()
            .then(() => setIsLoading(false));
        } else {
          setIsLoading(false);
        }
      });
      // Cleanup subscription on unmount
      return () => unsubscribe();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const signOut = useCallback(async () => {
    return dispatch(signOutAsync())
      .unwrap()
      .then(() => navigate("/login", { replace: true }));
  }, [dispatch, navigate]);

  const login = useCallback(
    async (email, password) => {
      return dispatch(loginWithEmail({ email, password }))
        .unwrap()
        .then(() => navigate("/", { replace: true }));
    },
    [dispatch, navigate],
  );

  const register = useCallback(
    async (email, password, additionalInfo) => {
      return dispatch(createUser({ email, password, additionalInfo }))
        .unwrap()
        .then(() => navigate("/", { replace: true }));
    },
    [dispatch, navigate],
  );

  const signInWithGoogle = useCallback(async () => {
    return dispatch(signInWithGoogleAsync());
  }, [dispatch]);

  return {
    currentUser,
    isLoading,
    login,
    signOut,
    register,
    signInWithGoogle,
  };
};

export default useAuth;
