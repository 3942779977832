import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentPredictor,
  selectIsLoading,
} from "redux-toolkit/predictors/selectors";
import {
  getPredictorByIdAsync,
  createPredictorAsync,
  updatePredictorAsync,
  deletePredictorAsync,
} from "redux-toolkit/predictors/actions";

const usePredictor = ({ id }) => {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentPredictor);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    id && dispatch(getPredictorByIdAsync(id));
  }, [dispatch, id]);

  const createPredictor = useCallback(
    (newPredictor) => {
      dispatch(createPredictorAsync(newPredictor));
    },
    [dispatch],
  );

  const updatePredictor = useCallback(
    (id, data) => {
      dispatch(updatePredictorAsync({ id, data }));
    },
    [dispatch],
  );

  const deletePredictor = useCallback(
    () => {
      dispatch(deletePredictorAsync(id));
    },
    [id, dispatch],
  );

  return {
    current,
    isLoading,
    createPredictor,
    updatePredictor,
    deletePredictor,
  };
};

export default usePredictor;
