import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./app-slice";
import articlesReducer from "redux-toolkit/articles/slice";
import usersReducer from "redux-toolkit/users/slice";
import dashboardReducer from "redux-toolkit/dashboard/slice";
import programReducer from "redux-toolkit/programs/slice";
import predictionsReducer from "redux-toolkit/predictions/slice";
import predictorsReducer from "redux-toolkit/predictors/slice";
import mainContentReducer from "redux-toolkit/main/slice";

const combinedReducer = combineReducers({
  app: appReducer,
  articles: articlesReducer,
  users: usersReducer,
  dashboard: dashboardReducer,
  programs: programReducer,
  predictions: predictionsReducer,
  predictors: predictorsReducer,
  main: mainContentReducer,
});

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
