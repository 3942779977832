import React from "react";
import { useParams } from "react-router-dom";
import usePrediction from "hooks/predictions/usePrediction";
import PredictionView from "components/predictions/PredictionView";
import Loading from "components/common/Loading";

const Predictions = ({ isEditable }) => {
  const { id } = useParams();
  const { current, isLoading } = usePrediction({ id });
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <PredictionView prediction={current} isEditable={isEditable} />
      )}
    </div>
  );
};

export default Predictions;
